.login-div {
	margin: 50px auto;
	padding: 60px 40px;
	width: 60vw;
	height: 25vh;
	background-color: #ffffff;
	border-radius: 15px;
	color: #171c20;
	opacity: 0.9;
}

.login.brand-logo {
	margin: 26px;
}

.copyright-text {
	margin: 6vh auto 0;
	font-size: 0.8rem;
}

@media (max-width: 1024px) {
	.login.backdrop {
		padding: 200px;
	}

	.login-div {
		height: 160px;
		padding: 40px;
	}
}

@media (max-width: 768px) {
	.login.backdrop {
		padding: 100px;
	}

	.login-div {
		height: 180px;
		padding: 30px;
	}
}

@media (max-width: 460px) {
	.login.backdrop {
		padding: 40px;
	}

	.login-div {
		height: 160px;
		padding: 20px;
	}
}

@media (max-width: 360px) {
	.login-div {
		height: 185px;
	}
}

@media (max-width: 320px) {
	.login.backdrop {
		padding: 20px;
	}

	.login-div {
		height: 160px;
		padding: 8px;
	}
}

@media (max-width: 280px) {
	.login.backdrop {
		padding: 0;
	}

	.login-div {
		height: 175px;
		padding: 5px;
	}
}
