.user-playlist {
	text-decoration: none;
	color: #ffffff;
	min-height: 220px;
	min-width: 200px;
}

.user-playlist img:hover {
	cursor: pointer;
}

.playlist-name {
	font-size: 14px;
	max-width: 200px;
}
